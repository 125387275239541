import { removeRegionFromPath } from 'lib/url/searchUrlUtils'
import { useLocation } from 'react-router'
import config from 'constants/config'

function useIsHomepage() {
  const pathname = removeRegionFromPath(useLocation().pathname)
  return pathname === (config.defaultHomePage ?? '/')
}

export default useIsHomepage
