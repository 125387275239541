import createSelector from 'lib/web/createSelector'
import { canAddInsuranceToOrder, haveOrdersBeenFetched, sortOrdersByDeparture } from 'lib/order/orderUtils'
import isUpcoming, { isExperienceItemUpcoming, isUpcomingForPartnerVelocityEarnEligibility } from 'lib/order/isUpcoming'
import { isEmpty, nonNullable } from 'lib/array/arrayUtils'
import { isOrderUpsellable } from 'actions/UpsellAndAvailableCreditSnackbar/utils'
import config from 'constants/config'
import { VIRGIN_VELOCITY_END_DATE, VIRGIN_VELOCITY_START_DATE } from 'constants/partnerships'
import { VELOCITY_ENABLED_REGIONS } from 'constants/config/region'

export const getOrdersThatCanHaveInsuranceAdded = createSelector(
  (state: App.State) => state.orders.orders,
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.offer.offers,
  (orders, regionCode, offers) => {
    return Object.values(orders).filter(order => {
      const offer = offers[order.items[0]?.offerId]
      return canAddInsuranceToOrder(order, regionCode, offer)
    })
  },
)

export const getOrderOffers = createSelector(
  (state: App.State) => state.offer.offers,
  (state: App.State, offerIds: Array<string>) => offerIds,
  (offers: Record<string, App.Offer>, offerIds: Array<string>) => {
    return nonNullable(offerIds.map(offerId => offers[offerId]))
  },
)

export const getUpcomingOrders = createSelector(
  (state: App.State) => state.orders.orders,
  (orders) => Object.values(orders).filter(isUpcoming),
)

export const getPendingOrders = createSelector(
  (state: App.State) => state.orders.orders,
  (orders) => Object.values(orders).filter(order => order.status === 'pending'),
)

const getNonEmptyOrders = (orders: Array<App.Order>) => orders.filter(order =>
  !isEmpty(order.items) ||
  !isEmpty(order.bedbankItems) ||
  !isEmpty(order.flightItems) ||
  !isEmpty(order.tourItems) ||
  !isEmpty(order.experienceItems) ||
  !isEmpty(order.cruiseItems) ||
  !isEmpty(order.carHireItems) ||
  !isEmpty(order.transferItems) ||
  !isEmpty(order.customOfferItems),
)

export const getNextUpsellableOrder = createSelector(
  getUpcomingOrders,
  (orders): App.Order => {
    const sortedOrders = sortOrdersByDeparture(
      getNonEmptyOrders(orders).filter(
        (order) => order.status !== 'cancelled' && isOrderUpsellable(order),
      ),
    )
    return sortedOrders[0]
  },
)

export const getSortedOrdersByDeparture = createSelector(
  (state: App.State) => haveOrdersBeenFetched(state),
  (state: App.State) => state.orders.orders,
  (ordersFetched, orders) => {
    const allUnsortedOrders = getNonEmptyOrders(Object.values(orders))
    return {
      fetched: ordersFetched,
      data: sortOrdersByDeparture(allUnsortedOrders),
    }
  },
)

export const getUpcomingExperienceItems = createSelector(
  (state: App.State) => state.orders.orders,
  (orders) => {
    return Object.values(orders).filter(Boolean).flatMap(order => {
      return order.experienceItems.filter(item => isExperienceItemUpcoming(item, order))
    })
  },
)

const isLuxuryescapes: boolean = config.BRAND === 'luxuryescapes'

export function isOrderVelocityEligiblePostCheckout(order: App.Order) {
  return isLuxuryescapes &&
  VELOCITY_ENABLED_REGIONS.includes(order?.regionCode) &&
  isUpcomingForPartnerVelocityEarnEligibility(order) &&
  order.createdAt > VIRGIN_VELOCITY_START_DATE &&
  order.createdAt <= VIRGIN_VELOCITY_END_DATE
}
